export const ENTRY_LIST_REQUEST = "ENTRY_LIST_REQUEST";
export const ENTRY_LIST_SUCCESS = "ENTRY_LIST_SUCCESS";
export const ENTRY_LIST_FAIL = "ENTRY_LIST_FAIL";

export const CREATE_ENTRY_REQUEST = "CREATE_ENTRY_REQUEST";
export const CREATE_ENTRY_SUCCESS = "CREATE_ENTRY_SUCCESS";
export const CREATE_ENTRY_FAIL = "CREATE_ENTRY_FAIL";

export const UPDATE_ENTRY_REQUEST = "UPDATE_ENTRY_REQUEST";
export const UPDATE_ENTRY_SUCCESS = "UPDATE_ENTRY_SUCCESS";
export const UPDATE_ENTRY_FAIL = "UPDATE_ENTRY_FAIL";

export const DELETE_ENTRY_REQUEST = "DELETE_ENTRY_REQUEST";
export const DELETE_ENTRY_SUCCESS = "DELETE_ENTRY_SUCCESS";
export const DELETE_ENTRY_FAIL = "DELETE_ENTRY_FAIL";
