import React, { Suspense, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NavBar from "./components/NavBar/NavBar";
import Footer from "./components/Footer/Footer";

const Calendar = React.lazy(() => import("./pages/Calendar/Calendar"));
const CreateEntry = React.lazy(() => import("./pages/EntryCreate/CreateEntry"));
const Docs = React.lazy(() => import("./pages/Docs/Docs"));
const LoginPage = React.lazy(() => import("./pages/UserLogin/LoginPage"));
const MainMenu = React.lazy(() => import("./pages/MainMenu/MainMenu"));
const Profile = React.lazy(() => import("./pages/UserProfile/Profile"));
const SingleEntry = React.lazy(() => import("./pages/EntryEdit/SingleEntry"));
const SignUp = React.lazy(() => import("./pages/UserSignup/SignUp"));
const Tracker = React.lazy(() => import("./pages/EntryTracker/Tracker"));

const App = () => {
  const [search, setSearch] = useState("");

  return (
    <BrowserRouter>
      <NavBar setSearch={setSearch} />
      <Suspense fallback={<p>Loading...</p>}>
        <Routes>
          <Route path="*" element={<MainMenu />} />
          <Route path="/" element={<MainMenu />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/create" element={<CreateEntry />} />
          <Route path="/Docs" element={<Docs />} />
          <Route path="/entry/:id" element={<SingleEntry />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/tracker" element={<Tracker search={search} />} />
        </Routes>
      </Suspense>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
