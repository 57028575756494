import { configureStore } from "@reduxjs/toolkit";
import {
  createEntryReducer,
  deleteEntryReducer,
  listEntriesReducer,
  updateEntryReducer,
} from "../redux_reducers/entryReducer";
import {
  loginUserReducer,
  registerUserReducer,
  updateProfileReducer,
} from "../redux_reducers/userReducers";

const reducer = {
  userLogin: loginUserReducer,
  userRegister: registerUserReducer,
  userUpdate: updateProfileReducer,
  entryList: listEntriesReducer,
  createEntry: createEntryReducer,
  updateEntry: updateEntryReducer,
  deleteEntry: deleteEntryReducer,
};

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const preloadedState = { userLogin: { userInfo: userInfoFromStorage } };

const store = configureStore({ preloadedState, reducer });

export default store;
