import {
  ENTRY_LIST_REQUEST,
  ENTRY_LIST_SUCCESS,
  ENTRY_LIST_FAIL,
  CREATE_ENTRY_REQUEST,
  CREATE_ENTRY_SUCCESS,
  CREATE_ENTRY_FAIL,
  UPDATE_ENTRY_REQUEST,
  UPDATE_ENTRY_SUCCESS,
  UPDATE_ENTRY_FAIL,
  DELETE_ENTRY_REQUEST,
  DELETE_ENTRY_SUCCESS,
  DELETE_ENTRY_FAIL,
} from "../redux_constants/entryConstants";

export const listEntriesReducer = (state = { entries: [] }, action) => {
  switch (action.type) {
    case ENTRY_LIST_REQUEST:
      return { loading: true };
    case ENTRY_LIST_SUCCESS:
      return { loading: false, entries: action.payload };
    case ENTRY_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const createEntryReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_ENTRY_REQUEST:
      return { loading: true };
    case CREATE_ENTRY_SUCCESS:
      return { loading: false, success: true };
    case CREATE_ENTRY_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const updateEntryReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_ENTRY_REQUEST:
      return { loading: true };
    case UPDATE_ENTRY_SUCCESS:
      return { loading: false, success: true };
    case UPDATE_ENTRY_FAIL:
      return { loading: false, error: action.payload, success: false };

    default:
      return state;
  }
};

export const deleteEntryReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_ENTRY_REQUEST:
      return { loading: true };
    case DELETE_ENTRY_SUCCESS:
      return { loading: false, success: true };
    case DELETE_ENTRY_FAIL:
      return { loading: false, error: action.payload, success: false };

    default:
      return state;
  }
};
