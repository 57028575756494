import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux_actions/userActions";
import "./navBar.css";

function NavBar({ setSearch }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const logoutHandler = () => {
    dispatch(logout());
    navigate("/");
    if (location.pathname === "/") {
      window.location.reload(true);
    }
  };
  const navClickHandle = () => {
    document.getElementById("navbarSupportedContent").className += "collapse";
  };

  return (
    <div className="nav-div mb-4 mt-4" id="nav-div">
      <nav className="navbar navbar-expand-lg navbar-dark">
        <div className="container d-flex justify-content-between ">
          <a
            className="navbar-brand"
            href="https://FaisalBahoo.com"
            style={{ color: "whitesmoke" }}
          >
            <h2>Faisal Bahoo</h2>
          </a>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="collapse navbar-collapse justify-content-end mt-4"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav d-flex align-items-center mb-4">
              <li className="nav-item">
                <a className="nav-link" href="/">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/docs">
                  Docs
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/calendar">
                  Calendar
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/tracker">
                  Tracker
                </a>
              </li>

              {userInfo ? (
                <div
                  className="nav-item dropdown"
                  style={{ textAlign: "center" }}
                >
                  <div
                    className="nav-link dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {userInfo.username}
                  </div>
                  <ul className="dropdown-menu">
                    <li>
                      <a
                        className="dropdown-item"
                        href="/profile"
                        onClick={() => {
                          navClickHandle();
                        }}
                        style={{
                          letterSpacing: "0",
                          textTransform: "capitalize",
                        }}
                      >
                        Account
                      </a>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={() => {
                          logoutHandler();
                          navClickHandle();
                        }}
                      >
                        Logout
                      </button>
                    </li>
                  </ul>
                </div>
              ) : (
                ""
              )}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default NavBar;
